
































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import userPreferenceDB from '@/lib/userPreference';
import { getComponent, sleep } from '@/utils/helpers';
import userProfileModule from '@/store/modules/userProfileModule';
import notificationsModule from '@/store/modules/notificationsModule';
import accountModule from '@/store/modules/accountModule';
import reportModule from '@/store/modules/reportModule';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';
import Multiselect from 'vue-multiselect';
import parsePhoneNumber from 'libphonenumber-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { clearSession } from '@/lib/auth';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import '';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqFilteringPopup: () => getComponent('tasqs/TasqFilteringPopup'),
    Datetime,
    VuePhoneNumberInput,
    Multiselect,
  },
})
export default class UserProfile extends Vue {
	savingProfile = false

  savingReport = false

	sendingInvite = false

   windowWidth = window.innerWidth;

	firstName = ''

	lastName = ''

  userCountryCode: any = 'US';

  userNationalNumber: any = '';

  userFullFormatNumber: any = {};

	user_role = 'Engineers'

  user_default_filter = ''

   user_default_page = ''

	team: any = []

	email = ''

	phoneNumber = ''

  signOutPopup = false;

	userInviteEmail = ''

	saveSuccessfulNotificationTitle = 'Changes saved'

	saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.'

	acceptingTasqs = true

	showSaveSuccessfulNotification = false

	notifyOnNewJob = true

	notifyOnReassignedTasq = true

	loadingProfilePage = false

	username = ''

	userInitial = ''

	dropdownLocation: any[] = []

   report = {
     period: 'Daily',
     day: '',
     time: new Date(new Date().setHours(0, 0, 0)).toISOString(),
   }

  filteringDetails: any = {
    typesOptions: [],
    selectedUsernames: [],
    selectedRoutes: [],
    selectedTeams: [],
    selectedAreas: [],
    selectedWaitingOn: [],
    cycleTimeGreaterThanFilter: null,
    cycleTimeLessThanFilter: null,
    unitsGreaterThanFilter: null,
    unitsLessThanFilter: null,
    defermentPercentGreaterThanFilter: null,
    defermentPercentLessThanFilter: null,

  }

  isOnlineApp = navigator.onLine;

   reports: any = []

   didToggleAcceptingTasqs() {
	  this.updateAcceptingTasqs();
   }

   get getCurrentName(): string {
     return getNameByEmail(workflowModule.user.email);
   }

   get teams() {
	   const structuredTeams: any = [];
	   for (let x = 0; x < workflowModule.teams.length; x++) {
		   structuredTeams.push(workflowModule.teams[x]);
	   }

     console.log(structuredTeams);

     return structuredTeams;
   }

   get disableGeneratePDF() {
     return !!(this.report.period === 'Weekly' && this.report.day === '');
   }

   didSelectDropdown(e) {
     // if (type == 'Status Changes' || type == 'Duplicate Tasq') {
     // console.log(e)
     const rect = e.srcElement.parentElement.getBoundingClientRect();
     this.dropdownLocation = [rect.x, rect.y + 80];

     // } else {
     // 	const rect = e.srcElement.getBoundingClientRect()
     // 	this.dropdownLocation = [rect.x - 13, rect.y + 60]
     // }
   }

   hideDropdown() {
     this.dropdownLocation = [];
   }

   resetButtonTapped() {
     this.firstName = workflowModule.user.firstName;
     this.lastName = workflowModule.user.lastName;
     this.email = workflowModule.user.email;
     this.phoneNumber = workflowModule.user.phoneNumber;
     this.username = workflowModule.user.username.toLowerCase();
     this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
     this.user_role = workflowModule.user.role;
     this.user_default_page = workflowModule.user.defaultPage;
     this.user_default_filter = workflowModule.user.defaultUserFilter;
     this.userCountryCode = parsePhoneNumber(this.phoneNumber)?.country;
     this.userNationalNumber = parsePhoneNumber(this.phoneNumber)?.nationalNumber;
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }

   get isMobile() {
     return this.windowWidth < 769;
   }

   beforeDestroy() {
     window.removeEventListener('resize', this.onResize);
     window.removeEventListener('online', this.onlineMode);
     window.removeEventListener('offline', this.offlineMode);
   }

   async onlineMode() {
     this.isOnlineApp = true;
   }

   async offlineMode() {
     this.isOnlineApp = false;
   }

   logout() {
     clearSession();
     this.$router.push({ name: 'Login' });
   }

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
     window.addEventListener('online', this.onlineMode);
     window.addEventListener('offline', this.offlineMode);
   }

   async created() {
     this.loadingProfilePage = true;
     await sleep(1000);
     await workflowModule.listTeams({ useExisting: true });
     await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
     this.loadingProfilePage = false;
     this.firstName = workflowModule.user.firstName;
     this.lastName = workflowModule.user.lastName;
     this.email = workflowModule.user.email;
     this.phoneNumber = workflowModule.user.phoneNumber;
     this.username = workflowModule.user.username.toLowerCase();
     this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
     this.user_role = workflowModule.user.role;
     this.user_default_page = workflowModule.user.defaultPage;
     this.user_default_filter = workflowModule.user.defaultUserFilter && ['Assigned to me', 'Assigned to my team', 'Custom'].includes(workflowModule.user.defaultUserFilter) ? workflowModule.user.defaultUserFilter : 'Assigned to me';
     this.userCountryCode = parsePhoneNumber(this.phoneNumber)?.country;
     this.userNationalNumber = parsePhoneNumber(this.phoneNumber)?.nationalNumber;
     this.reports = await reportModule.getTLReport();
      this.team = workflowModule.user.team ? [workflowModule.user.team]  : []

    //  this.team = [];
    //  for (let x = 0; x < accountModule.user.team.length; x++) {
    //    this.team.push(accountModule.user.team[x]);
    //  }

     if (this.user_default_filter === 'Custom' && this.filteringDetails) {
       const defaultFilteringSettings: any = await userPreferenceDB.getItem('user-tasq-page-filtering-settings');
       if (defaultFilteringSettings && Object.keys(defaultFilteringSettings) && Object.keys(defaultFilteringSettings).length) {
         console.log(defaultFilteringSettings);
         this.filteringDetails = defaultFilteringSettings;
       }
     }
   }

   sleep(ms) {
	  return new Promise((resolve) => setTimeout(resolve, ms));
   }

   async sendInvite() {
	  this.sendingInvite = true;
	  await userProfileModule.sendInvitationEmail({
	    user_email: accountModule.user.email,
	    recipient_email: this.userInviteEmail,
	    user_first_name: this.userInviteEmail,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Invitation sent';
	    this.showSaveSuccessfulNotification = true;
	    this.userInviteEmail = '';
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
	  this.sendingInvite = false;
   }

   async updateNotificationSettings() {
	  // this.savingProfile = true

	  await notificationsModule.updateNotificationSettings({
	    username: accountModule.user.email.toLowerCase(),
	    notify_on_new_job: this.notifyOnNewJob,
	    notify_on_reassigned_tasq: this.notifyOnReassignedTasq,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
   }

   openReportTab() {
     window.open(`${window.location.origin}/report/7`);
   }

   async updateAcceptingTasqs() {
	  // this.savingProfile = true

	  await userProfileModule.updateAcceptingTasqs({
	    username: accountModule.user.email.toLowerCase(),
	    accepting_tasqs: this.acceptingTasqs,
	  }).then(() => {
	    // this.savingProfile = false
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
	  });
   }

   async saveTLReport() {
     this.savingReport = true;
     await reportModule.generateTLReport(this.report).then(async () => {
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
       this.reports = await reportModule.getTLReport();
       this.savingReport = false;
	  });
   }

   async deleteTLReport({ TLReportID }) {
     await reportModule.deleteTLReport(TLReportID).then(async () => {
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
       this.reports = await reportModule.getTLReport();
	  });
   }

   async saveProfile() {
     try {
       this.savingProfile = true;
       const localTeam: any[] = [];
       for (let x = 0; x < this.team.length; x++) {
         localTeam.push(this.team[x].value);
       }

       if (this.userFullFormatNumber) {
         this.phoneNumber = this.userFullFormatNumber.formattedNumber;
       }

       if (this.user_default_filter === 'Custom' && this.filteringDetails) {
         await userPreferenceDB.setItem('user-tasq-page-filtering-settings', this.filteringDetails);
       }

       // console.log(this.user_default_page)
	  await userProfileModule.updateUserProfile({
	    username: accountModule.user.email.toLowerCase(),
	    accepting_tasqs: true,
	    first_name: this.firstName,
	    last_name: this.lastName,
         team: this.team,
	    phone_number: this.phoneNumber,
	    roles: [this.user_role],
         default_page: this.user_default_page,
         default_user_filter: this.user_default_filter,
	    user_email: accountModule.user.email.toLowerCase(),
	  }).then(async () => {
      tasqsListModule.setLocalFilter(false);
         this.saveSuccessfulNotificationSubTitle = 'You may navigate away from this page.';
	    this.saveSuccessfulNotificationTitle = 'Changes saved';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
         await accountModule.getReassignmentList({ useExisting: false });
	  });
     } catch (error: any) {
       this.savingProfile = false;
       this.saveSuccessfulNotificationSubTitle = 'Something went wrong please try again.';
       this.saveSuccessfulNotificationTitle = 'Unable to save changes';
	    this.savingProfile = false;
	    this.showSaveSuccessfulNotification = true;
	    this.sleep(4000).then(() => {
	      this.showSaveSuccessfulNotification = false;
	    });
       console.log(error.message);
     }

     //  window.location.reload()
   }

   didSelectDropdownOption() {
     this.dropdownLocation = [];
   }
}
